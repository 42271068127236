
import Vue from 'vue';
import { VueConstructor } from 'vue/types';
import type { MetaInfo } from 'vue-meta';
import { tabChangeTitleListener } from '@/_helpers/misc_helper';
import localeHandler from '@/mixins/localeHandler';

import { JsonLD } from '@/types/misc_data';
import { routesNames } from '@/router';

import UTILS from '@/store/modules/UtilityModule';
import USER from '@/store/modules/UserModule';
import CART from '@/store/modules/CartModule';
import MISC_DATA from '@/store/modules/MiscDataModule';
import currentDomain from '@/mixins/currentDomain';

export default (Vue as VueConstructor<Vue & InstanceType<typeof localeHandler>>).extend({
	name: 'App',

	mixins: [localeHandler, currentDomain],

	data() {
		return {
			data_ready: false,

			offset_top: 0
		};
	},

	// Nuxt method head() uses vue-meta to update the headers and html attributes.
	head(): MetaInfo {
		return {
			titleTemplate: '123-Transporter | %s',
			title: this.currentMetaData ? this.currentMetaData.title.content : '123-Transporter',
			htmlAttrs: {
				lang: this.currentLang
			},
			meta: [this.currentMetaData ? this.currentMetaData.desc : {}, this.currentMetaData ? this.currentMetaData.mainDesc : {}],
			script: this.currentScripts,
			__dangerouslyDisableSanitizersByTagID: {
				'google-analytics': ['innerHTML'],
				'fb-pixel': ['innerHTML'],
				mouseflow: ['innerHTML'],
				gtm: ['innerHTML'],
				'google-tag-manager': ['innerHTML']
			},
			link: [this.canonicalLink]
		};
	},

	computed: {
		UTILS: () => UTILS,
		USER: () => USER,
		MISC_DATA: () => MISC_DATA,
		CART: () => CART,

		currentScripts(): any[] {
			// sets default jsonld or stored one if component has a custom one
			let scripts = this.MISC_DATA.html_header_base_scripts as any;

			if (process.env.NODE_ENV === 'production') {
				const prod_scripts = this.isDeDomain ? this.MISC_DATA.html_header_prod_scripts_de : this.MISC_DATA.html_header_prod_scripts;
				scripts = prod_scripts;
			}

			//add override to block google-fonts
			const block_google_fonts_script = {
				vmid: 'block_google_fonts_script',
				id: 'block_google_fonts_script',
				innerHTML: `

				var head = document.getElementsByTagName('head')[0];
				// set default value to track if the method was replaced
				head._scriptReplaced = head._scriptReplaced ?? false;

				// only continue if the replacement is not done yet
				if(!head._scriptReplaced){

					const isGoogleFont = function (element) {
						// google font download
						if (element.href
							&& element.href.indexOf('https://fonts.googleapis.com') === 0) {
							return true;
						}       
						return false;
					}

					// we override these methods only for one particular head element
					// default methods for other elements are not affected
					const insertBefore = head.insertBefore;
					head.insertBefore = function (newElement, referenceElement) {
						if (!isGoogleFont(newElement)) {
							// console.log(newElement);
							insertBefore.call(head, newElement, referenceElement);
						}
					};

					// set prop to remember the replacement
					head._scriptReplaced = true;
				}
				`
			};
			scripts = [block_google_fonts_script, ...scripts];

			if (this.chatScript && this.chatScript.length > 0) {
				scripts = [...scripts, ...this.chatScript];
			}

			const jsonld = {
				type: 'application/ld+json',
				json: new JsonLD()
			};

			if (this.$route.name === routesNames.faq || this.$route.name === routesNames.landing_page) {
				jsonld.json = this.MISC_DATA.jsonld;
			}

			return [...scripts, ...[jsonld]];
		},

		chatScript(): any[] {
			if (!this.isCzDomain || this.isMobile) {
				return [];
			}
			return [this.MISC_DATA.chatling_script];
		},

		bookingFocusMode(): boolean {
			const focus_routes = [routesNames.upsells_1, routesNames.upsells_2, routesNames.summary];
			return focus_routes.includes(this.$route.name as routesNames);
		},

		pageLoading(): boolean {
			return this.UTILS.page_loading;
		},

		redirectLoading(): boolean {
			return this.UTILS.redirect_loading;
		},

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		showFeedbackBtn(): boolean {
			if (this.bookingFocusMode) {
				return false;
			}
			const domain = this.$getDomain();
			const blacklist_routes = [routesNames.booking, routesNames.upgrade_booking, routesNames.fuel_receipt, routesNames.home_per_location];
			const path_allowed = !blacklist_routes.includes(this.$route.name as routesNames);
			const domain_allowed = ['hu'].includes(domain);
			return this.offset_top > 380 && path_allowed && domain_allowed;
		},

		test(): string {
			return this.$route.path;
		},

		canonicalLink(): any {
			if (!this.$route.path) {
				return undefined;
			}
			return {
				hid: 'page-canonical',
				rel: 'canonical',
				href: this.$config[`url_${this.$getDomain()}`] + this.$route.path
			};
		},

		currentLang(): string {
			const tld = this.$getDomain();
			if (tld === 'cz') {
				return 'cs';
			}
			return tld;
		},

		routeName(): string {
			const r = this.$route.name?.toLowerCase();
			return r?.replace(/\s/g, '') as string;
		},

		currentMetaData(): any {
			// UTILS.meta_data is filled in the fetchTranslation middleware so we have metadata in each languages.
			return (this.MISC_DATA.meta_data as any)[this.routeName];
		},

		showNavBar(): boolean {
			if (this.bookingFocusMode) {
				return false;
			}
			return !(this.$route.name === routesNames.info && !USER.data.is_complete);
		},

		showFooter(): boolean {
			if (this.bookingFocusMode) {
				return false;
			}
			const r = this.$route.name;
			return r !== routesNames.upsells_1 && r !== routesNames.upsells_2 && r !== routesNames.info && r !== routesNames.summary && r !== routesNames.checkout;
		}
	},

	// beforeMount() {
	// 	// Prevent other scripts from running until this one has loaded
	// 	// Create and insert the script
	// 	if (this.isDeDomain) {
	// 		const script = document.createElement('script');
	// 		script.src = 'https://cloud.ccm19.de/app.js?apiKey=d0944348dbbb4f81368760120093a1b4deaab6c3a55fb569&domain=66b1ee18b850eb0e070ed1f2';
	// 		// script.async = true;

	// 		// Set a callback when the script is loaded
	// 		script.onload = () => {
	// 			document.dispatchEvent(new Event('ccm19-loaded')); // Dispatch an event to signal that the script has loaded
	// 		};

	// 		// Insert the script into the head
	// 		document.head.appendChild(script);
	// 	}
	// },

	async mounted() {
		this.data_ready = true;

		if (this.$route.query.dialog) {
			// clear dialog queries in case of reload
			this.$logger.console({ message: 'clear dialog queries in case of reload' });
			this.$router.push({ path: this.$route.path });
		}

		if (this.$route.name !== routesNames.home && localStorage.getItem('booking') !== null && !(await this.CART.CHECK_BOOKING())) {
			this.$logger.console({ message: 'Stored booking invalid - cleared everything and redirecting home' });
			this.$router.replace({ name: routesNames.home });
		}

		this.MISC_DATA.storeBaseUrl({
			at: this.$config.url_at,
			de: this.$config.url_de,
			cz: this.$config.url_cz,
			hu: this.$config.url_hu
		});

		tabChangeTitleListener();

		let api_base_url = this.$config.apiBaseUrl;

		if (api_base_url) {
			// This needs to be there for the auth.
			// The Top Level Domain of the api call needs to be the same as the Top Level Domain of the currently served webiste.
			api_base_url = api_base_url.replace('.at', `.${this.$getDomain()}`);
			this.$axios.defaults.baseURL = api_base_url;
		}
	},

	methods: {
		onScroll(e: any): void {
			this.offset_top = e.target.scrollingElement.scrollTop;
		},

		async changeLocale(locale: string): Promise<void> {
			this.UTILS.setPageLoader(true);

			sessionStorage.setItem('lang', locale);
			await this.loadLocaleMessagesAsync(locale);

			this.UTILS.setPageLoader(false);
		}
	}
});
