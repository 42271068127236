import Router from 'vue-router';

enum routesNames {
	home = 'Home',
	home_per_location = 'HomePerLocation',
	landing_page = 'LandingPage',
	seo_page_wrapper = 'SeoPages',
	page_not_found = 'PageNotFound',

	login = 'Login',
	signup = 'Signup',
	reset_password_wrapper = 'ResetPasswordWrapper',
	reset_password = 'ResetPassword',

	partner_obi = 'PartnerObi',
	partner_bellaflora = 'PartnerBellaflora',
	partner_dehner = 'PartnerDehner',
	partner_bauwelt = 'PartnerBauwelt',
	partner_sconto = 'PartnerSconto',
	partner_teubl = 'PartnerTeubl',
	partner_raiffeisen_lagerhaus = 'PartnerRaiffeisenLagerhaus',
	partner_hornbach = 'PartnerHornbach',
	partner_olympia = 'PartnerOlympia',

	upsells_1 = 'Upsells1',
	upsells_2 = 'Upsells2',
	account = 'Account',
	info = 'Info',
	extra_payment_wrapper = 'ExtraPaymentWrapper',
	extra_payment = 'ExtraPayment',
	extra_payment_thanks = 'ExtraPaymentThanks',

	about = 'About',
	contact = 'Contact',
	agb = 'AGB',
	agb_abo = 'AGBAbo',
	agb_b2b = 'AGBB2b',
	privacy_policy = 'PrivacyPolicy',
	privacy_policy_phone = 'PrivacyPolicyPhone',

	faq = 'FAQ',
	jobs = 'Jobs',
	fleet_partner = 'FleetPartner', // Flottenpartner Werden
	location_partner = 'LocationPartner', // Standortpartner werden
	long_term_rent = 'LongTermRent',
	imprint = 'Imprint',
	payment_methods = 'PaymentMethods',
	sustainability = 'Sustainability',
	usage_rules = 'UsageRules',
	all_trucks = 'AllTrucks',
	truck_wrapper = 'TruckWrapper',
	truck = 'Truck',
	our_app = 'OurApp',
	cust_experience = 'CustomerExperience',
	damage_info = 'DamageInfo',

	// Checkout routes // using the step prop in Booking (see checkoutStepRedirector in routes.ts)
	booking = 'Booking', // useless template route
	checkout = 'Checkout',
	summary = 'Summary',
	app = 'ProposeApp',

	// Profile
	profile = 'Profile',
	app_login_link = 'AppLoginLink',
	bookings = 'Bookings',
	booking_details = 'BookingDetails',
	fuel_receipt = 'UploadFuelReceipt',
	edit_license = 'EditLicense',
	abo = 'ProAbo',
	cancel_abo = 'CancelAbo',
	invoices = 'Invoices',
	upgrade_booking = 'UpgradeBooking',
	booking_upgraded = 'BookingUpgraded',
	booking_upgraded_summary = 'BookingUpgradedSummary',
	booking_upgraded_thanks = 'BookingUpgradedThanks',

	press = 'Press',
	press_list = 'PressList',
	press_article = 'PressArticle',
	press_epk = 'PressEPK',
	us_in_media = 'UsInMedia'
}

const paths_per_bu = {
	[routesNames.home]: {
		default: '/'
	},
	[routesNames.login]: {
		default: '/login',
		at: '/anmelden',
		de: '/anmelden',
		cz: '/prihlaseni',
		hu: '/bejelentkezes'
	},
	[routesNames.signup]: {
		default: '/signup',
		at: '/registrierung',
		de: '/registrierung',
		cz: '/registrace',
		hu: '/regisztracio'
	},
	[routesNames.profile]: {
		default: '/profile',
		at: '/profil',
		de: '/profil',
		cz: '/profil',
		hu: '/felhasznaloi-fiok'
	},
	[routesNames.app_login_link]: {
		default: 'login-link',
		at: 'anmelde-link',
		de: 'anmelde-link',
		cz: 'prihlaseni-odkaz',
		hu: 'bejelentkezo-link'
	},
	[routesNames.bookings]: {
		default: 'bookings',
		at: 'buchungen',
		de: 'buchungen',
		cz: 'rezervace',
		hu: 'berlesek'
	},
	[routesNames.invoices]: {
		default: 'invoices',
		at: 'rechnungen',
		de: 'rechnungen',
		cz: 'faktury',
		hu: 'szamlak'
	},
	[routesNames.abo]: {
		default: 'pro-abo'
	},
	[routesNames.booking_details]: {
		default: ':id'
	},
	[routesNames.upgrade_booking]: {
		default: 'upgrade',
		at: 'erweitern',
		de: 'erweitern',
		cz: 'vylepseni',
		hu: 'kiegeszites'
	},
	[routesNames.booking_upgraded]: {
		default: ':upgrade_id'
	},
	[routesNames.booking_upgraded_summary]: {
		default: 'summary',
		at: 'zusammenfassung',
		de: 'zusammenfassung',
		cz: 'shrnuti',
		hu: 'osszefoglalo'
	},
	[routesNames.booking_upgraded_thanks]: {
		default: 'thanks',
		at: 'danke',
		de: 'danke',
		cz: 'podekovani',
		hu: 'koszonjuk'
	},
	[routesNames.fuel_receipt]: {
		default: 'fuel-receipt',
		at: 'tankbeleg',
		de: 'tankbeleg',
		cz: 'uctenka-za-palivo',
		hu: 'tankolas'
	},
	[routesNames.edit_license]: {
		default: 'edit-license',
		at: 'fuehrerschein',
		de: 'fuehrerschein',
		cz: 'upravit-licenci',
		hu: 'jogositvany'
	},
	[routesNames.cancel_abo]: {
		default: 'cancel-abo',
		de: 'abo-storno',
		at: 'abo-storno',
		cz: 'zrusit-abo',
		hu: 'tagsag-felmondas'
	},
	[routesNames.reset_password_wrapper]: {
		default: '/forgot-password',
		at: '/passwort-vergessen',
		de: '/passwort-vergessen',
		cz: '/zapomenute-heslo',
		hu: '/elflejtett-jelszo'
	},
	[routesNames.reset_password]: {
		default: ':token'
	},
	[routesNames.booking]: {
		default: '/booking',
		at: '/buchen',
		de: '/buchen',
		cz: '/rezervace',
		hu: '/berles'
	},
	[routesNames.upsells_1]: {
		default: 'select-protection-package',
		at: 'schutzpaket-aussuchen',
		de: 'schutzpaket-aussuchen',
		cz: 'volba-balicku-pojisteni',
		hu: 'karkockazat-csokkento-csomag'
	},
	[routesNames.upsells_2]: {
		default: 'select-booking-extras',
		at: 'buchungsextras-aussuchen',
		de: 'buchungsextras-aussuchen',
		cz: 'volba-doplnku',
		hu: 'kiegeszito-csomag-valasztasa'
	},
	[routesNames.info]: {
		default: 'cust-info',
		at: 'kundeninformation',
		de: 'kundeninformation',
		cz: 'info-zakaznik',
		hu: 'felhasznalo-info'
	},
	[routesNames.checkout]: {
		default: ':id'
	},
	[routesNames.summary]: {
		default: 'summary',
		at: 'zusammenfassung',
		de: 'zusammenfassung',
		cz: 'shrnuti',
		hu: 'osszefoglalo'
	},
	[routesNames.app]: {
		default: 'app'
	},
	[routesNames.extra_payment_wrapper]: {
		default: '/payment',
		at: '/bezahlen',
		de: '/bezahlen',
		cz: '/platba',
		hu: '/fizetes'
	},
	[routesNames.extra_payment]: {
		default: ':id'
	},
	[routesNames.extra_payment_thanks]: {
		default: 'thanks',
		at: 'danke',
		de: 'danke',
		cz: 'czthanks',
		hu: 'koszonjuk'
	},
	[routesNames.about]: {
		default: '/about',
		at: '/ueber-uns',
		de: '/ueber-uns',
		cz: '/o-nas',
		hu: '/rolunk'
	},
	[routesNames.imprint]: {
		default: '/imprint',
		at: '/impressum',
		de: '/impressum',
		cz: '/impresum',
		hu: '/impresszum'
	},
	[routesNames.contact]: {
		default: '/contact',
		at: '/kontakt',
		de: '/kontakt',
		cz: '/kontakt',
		hu: '/kapcsolat'
	},
	[routesNames.faq]: {
		default: '/faq',
		at: '/informationen-zur-anmietung',
		de: '/informationen-zur-anmietung',
		cz: '/faq',
		hu: '/gyik'
	},
	[routesNames.usage_rules]: {
		default: '/rules',
		at: '/regeln',
		de: '/regeln',
		cz: '/pravidla',
		hu: '/szabalyaink'
	},
	[routesNames.sustainability]: {
		default: '/sustainability',
		at: '/nachhaltigkeit',
		de: '/nachhaltigkeit',
		cz: '/udrzitelnost',
		hu: '/fenntarthatosag'
	},
	[routesNames.payment_methods]: {
		default: '/payment-methods',
		at: '/zahlungsmethoden',
		de: '/zahlungsmethoden',
		cz: '/platebni-metody',
		hu: '/fizetesi-lehetosegeid'
	},
	[routesNames.jobs]: {
		default: '/jobs',
		at: '/karriere',
		de: '/karriere',
		cz: '/kariera',
		hu: '/karrier'
	},
	[routesNames.fleet_partner]: {
		default: '/become-fleet-partner',
		at: '/jetzt-flottenpartner-werden',
		de: '/jetzt-flottenpartner-werden',
		cz: '/stante-se-fleet-partnerem',
		hu: '/legyel-flotta-partnerunk'
	},
	[routesNames.location_partner]: {
		default: '/become-location-partner',
		at: '/jetzt-standortpartner-werden',
		de: '/jetzt-standortpartner-werden',
		cz: '/stante-se-location-partnerem',
		hu: '/legyel-lokacios-partnerunk'
	},
	[routesNames.long_term_rent]: {
		default: '/long-term-rental-without-mileage-limit',
		at: '/transporter-langzeitmiete-ohne-kilometerbegrenzung',
		de: '/transporter-langzeitmiete-ohne-kilometerbegrenzung',
		cz: '/dlouhodoby-pronajem',
		hu: '/hosszu-tavu-berles-korlatlan-kilometerrel'
	},
	[routesNames.all_trucks]: {
		default: '/all-vans',
		at: '/alle-transporter',
		de: '/alle-transporter',
		cz: '/lokality',
		hu: '/osszes-furgon'
	},
	[routesNames.truck_wrapper]: {
		default: '/van',
		at: '/transporter',
		de: '/transporter',
		cz: '/dodavka',
		hu: '/furgon'
	},
	[routesNames.truck]: {
		default: ':id'
	},
	[routesNames.press]: {
		default: '/press',
		at: '/presse',
		de: '/presse',
		cz: '/media',
		hu: '/sajto'
	},
	[routesNames.press_list]: {
		default: 'articles',
		at: 'artikel',
		de: 'artikel',
		cz: 'clanky',
		hu: 'cikk'
	},
	[routesNames.us_in_media]: {
		default: 'us-in-media',
		at: 'wir-in-der-presse',
		de: 'wir-in-der-presse',
		cz: 'my-v-mediich',
		hu: 'sajto-megjeleneseink'
	},
	[routesNames.press_epk]: {
		default: 'epk'
	},
	[routesNames.seo_page_wrapper]: {
		default: '/lkw-mieten'
	},
	[routesNames.home_per_location]: {
		default: '/map'
	},
	[routesNames.our_app]: {
		default: '/our-app',
		at: '/unsere-app',
		de: '/unsere-app',
		cz: '/nase-aplikace',
		hu: '/mi-applikacionk'
	},
	[routesNames.cust_experience]: {
		default: '/customer-experience',
		at: '/erfahrungsberichte',
		de: '/erfahrungsberichte',
		cz: '/vase-zazitky',
		hu: '/elmenybeszamolok'
	},
	[routesNames.damage_info]: {
		default: '/crash-and-damage-forms',
		at: '/unfall-und-schaden-formulare',
		de: '/unfall-und-schaden-formulare',
		cz: '/pruvodce-skoda-nebo-nehoda',
		hu: '/baleset-es-karbejelento-lap'
	},
	[routesNames.partner_obi]: {
		default: 'vans-in-obi-market',
		at: 'transporter-mieten-im-obi-markt-miettransporter',
		de: 'transporter-mieten-im-obi-markt-miettransporter',
		cz: 'pronajem-dodavky-obi',
		hu: 'furgon-berles-obi'
	},
	[routesNames.partner_bellaflora]: {
		default: 'vans-in-bellaflora',
		at: 'transporter-mieten-im-bellaflora-miettransporter',
		de: 'transporter-mieten-im-bellaflora-miettransporter',
		cz: 'pronajem-dodavky-bellaflora',
		hu: 'furgon-berles-bellaflora'
	},
	[routesNames.partner_dehner]: {
		default: 'vans-in-dehner-garden',
		at: 'transporter-mieten-im-dehner-garten-center-miettransporter',
		de: 'transporter-mieten-im-dehner-garten-center-miettransporter',
		cz: 'pronajem-dodavky-dehner',
		hu: 'furgon-berles-dehner'
	},
	[routesNames.partner_bauwelt]: {
		default: 'vans-in-obi-bauwelt',
		at: 'transporter-mieten-im-obi-bauwelt-miettransporter',
		de: 'transporter-mieten-im-obi-bauwelt-miettransporter',
		cz: 'pronajem-dodavky-obi-bauwelt',
		hu: 'furgon-berles-obi-bauwelt'
	},
	[routesNames.partner_sconto]: {
		default: 'vans-in-sconto',
		at: 'transporter-mieten-im-sconto-miettransporter',
		de: 'transporter-mieten-im-sconto-miettransporter',
		cz: 'pronajem-dodavky-sconto',
		hu: 'furgon-berles-sconto'
	},
	[routesNames.partner_hornbach]: {
		default: 'vans-in-hornbach',
		at: 'transporter-mieten-beim-hornbach-markt-miettransporter',
		de: 'transporter-mieten-im-hornbach-miettransporter',
		cz: 'pronajem-dodavky-hornbach',
		hu: 'furgon-berles-hornbach'
	},

	[routesNames.partner_olympia]: {
		default: 'vans-in-olympia',
		at: 'transporter-mieten-beim-olympia-markt-miettransporter',
		de: 'transporter-mieten-im-olympia-miettransporter',
		cz: 'pronajem-dodavky-olympia',
		hu: 'furgon-berles-olympia'
	},

	[routesNames.partner_teubl]: {
		default: 'vans-in-obi-teubl',
		at: 'transporter-mieten-im-obi-teubl-miettransporter',
		de: 'transporter-mieten-im-obi-teubl-miettransporter',
		cz: 'pronajem-dodavky-obi-teubl',
		hu: 'furgon-berles-obi-teubl'
	},
	[routesNames.partner_raiffeisen_lagerhaus]: {
		default: 'vans-in-raiffeisen-lagerhaus',
		de: 'transporter-mieten-bei-raiffeisen-lagerhaus-miettransporter',
		at: 'transporter-mieten-bei-raiffeisen-lagerhaus-miettransporter',
		cz: 'pronajem-dodavky-raiffeisen-lagerhaus',
		hu: 'furgon-berles-raiffeisen-lagerhaus'
	},
	[routesNames.landing_page]: {
		default: '/transporter-mieten-*',
		at: '/transporter-mieten-*',
		de: '/transporter-mieten-*',
		cz: '/pronajem-dodavky-*',
		hu: '/furgon-berles-*'
	},
	[routesNames.privacy_policy]: {
		default: '/privacy-policy',
		at: '/datenschutz',
		de: '/datenschutz',
		cz: '/ochrana-osobnich-udaju',
		hu: '/adatvedelem'
	},
	[routesNames.privacy_policy_phone]: {
		default: '/privacy-policy-recording',
		at: '/datenschutz-aufzeichnung',
		de: '/datenschutz-aufzeichnung',
		cz: '/ochrana-osobnich-udaju-zaznam',
		hu: '/adatvedelem-felvetel'
	},
	[routesNames.agb]: {
		default: '/terms-and-conditions',
		at: '/agb',
		de: '/agb',
		cz: '/vop',
		hu: '/aszf'
	},
	[routesNames.agb_abo]: {
		default: '/terms-and-conditions-123-pro',
		at: '/agb-abo',
		de: '/agb-abo',
		cz: '/vop-123-pro',
		hu: '/aszf-tagsag'
	},
	[routesNames.agb_b2b]: {
		default: '/terms-and-conditions-b2b',
		at: '/agb-b2b',
		de: '/agb-b2b',
		cz: '/vop-b2b',
		hu: '/aszf-b2b'
	},
	[routesNames.page_not_found]: {
		default: '/404'
	}
} as {
	[index: string]: { [index: string]: string };
};

function getRoutePath(route: routesNames, bu: string): string | undefined {
	bu = bu.toLowerCase();

	if (!paths_per_bu[route]) {
		return undefined;
	}

	const list = paths_per_bu[route] as { [index: string]: string };
	if (list && list[bu]) {
		return list[bu];
	}

	return list.default;
}

function getRouteAliases(route: routesNames): string[] {
	const list = paths_per_bu[route] as { [index: string]: string };

	const filtered = Object.entries(list).filter(([k, v]) => {
		return k !== 'default' && getBasePath(route) !== v;
	});

	const filtered_list = Object.values(Object.fromEntries(filtered));

	return [...new Set(filtered_list)];
}

function getBasePath(route: routesNames): string {
	const list = paths_per_bu[route] as { [index: string]: string };
	return list.default;
}

function getAllPaths(route: routesNames): string[] | undefined {
	const list = paths_per_bu[route];
	if (!list) {
		return undefined;
	}
	return [...new Set(Object.values(list))];
}

function handlePath(parameters: {
	src_path: string;
	tld: string;
	routes: any;
	return_obj: boolean;
	bu_specific_routes?: any;
}): string | routesNames | { path: string; query: any; hash: string } {
	// Gets rid of trailing slash first
	if (parameters.src_path !== '/' && parameters.src_path.endsWith('/')) {
		parameters.src_path = parameters.src_path.replace(/\/+$/, '') || '/';
	}
	// Create an instance of router to get access to match and resolve function
	const router = new Router({ mode: 'history', routes: parameters.routes });
	const route = router.match(parameters.src_path) as any;
	const matched_paths = route.matched;
	const params = route.params;

	let translated_path = '';

	// Handle if it's a AGB route and return the pdf path stored in cached_urls
	const agb_paths = [routesNames.agb, routesNames.agb_abo, routesNames.agb_b2b];

	if (agb_paths.includes(route.name)) {
		const specific_routes = JSON.parse(parameters.bu_specific_routes);
		let agb_key: any;
		switch (route.name) {
			case routesNames.agb:
				agb_key = 'agb_link'
				break;
			case routesNames.agb_abo:
				agb_key = 'agb_abo_link'
				break;
			case routesNames.agb_b2b:
				agb_key = 'agb_b2b_link'
		}
		const agb_path = specific_routes.documents[parameters.tld][agb_key]
		if (agb_path) {
			return agb_path
		}
	}

	// if (route.name === routesNames.press_article && parameters.bu_specific_routes) {
	// 	const specific_routes = JSON.parse(parameters.bu_specific_routes);
	// 	const slug = parameters.src_path.split('/').pop();
	// 	// Check if the press article match the tld
	// 	if (specific_routes.press[parameters.tld] && specific_routes.press[parameters.tld].includes(slug)) {
	// 		console.log('Correct press article slug with current tld');
	// 	} else {
	// 		console.log('Incorrect press article slug');
	// 		return '/404';
	// 	}
	// }

	// Same for the landing pages?

	const blacklisted_routes = [routesNames.landing_page, routesNames.page_not_found, routesNames.home, routesNames.seo_page_wrapper];
	const abort_translation = blacklisted_routes.includes(route.name as routesNames);

	if (!abort_translation) {
		matched_paths.forEach((r: any) => {
			// Check for wildcard and return it
			if (r.path.includes('*')) {
				translated_path += `/${route.fullPath.split('/').pop()}`;
				return;
			}

			let t_path = getRoutePath(r.name, parameters.tld);

			if (!t_path || r.name === routesNames.home) {
				return;
			}
			// Check for token and replace by its actual param value
			if (t_path.includes(':') && params) {
				t_path = params[t_path.replace(':', '')];
			}
			// add slash if it's a child route
			if (t_path && !t_path.includes('/')) {
				t_path = `/${t_path}`;
			}
			translated_path += t_path;
		});
	}

	const to_path = abort_translation ? route.path : translated_path;

	const { query, hash } = route;
	const next_path = to_path.replace(/\/+$/, '') || '/';
	const next_route = { path: next_path, query, hash };

	// Make sure to encode this result if using the router resolve (we had URI encoding errors)
	return parameters.return_obj ? next_route : router.resolve(next_route).href;
}

export { routesNames };
export { paths_per_bu, getRouteAliases, getRoutePath, getBasePath, getAllPaths, handlePath };
