import { SerializeStoreObject } from '@/types/class_helper';
import { routesNames } from '~/router';
import { checkoutStepRedirector } from '@/router/routes';

export class IBookingUpsells extends SerializeStoreObject {
	insurance: string;
	international: string[];
	cancel: boolean;
	drivers: {
		count: number;
		user_is_driver: boolean;
		list: any[];
	};

	constructor() {
		super();

		this.insurance = '';
		this.international = [];
		this.cancel = false;
		this.drivers = {
			count: 0,
			user_is_driver: true,
			list: []
		};
	}
}

export class IBookingSteps extends SerializeStoreObject {
	previous: string | null;
	current: string | null;
	next: string | null;
	info: any;

	constructor() {
		super();

		this.previous = '';
		this.current = '';
		this.next = '';
		this.info = null;
	}
}

export class IBookingBilling extends SerializeStoreObject {
	positions: [
		{
			amount: number;
			name: string;
			price: string;
			price_total: number;
			tax: number;
			type: string;
			pos?: number;
			info?: string;
		}
	];

	total: number;
	total_net: number;
	total_tax: number;

	constructor() {
		super();

		this.positions = [
			{
				amount: 0,
				name: '',
				price: '',
				price_total: 0,
				tax: 0,
				type: ''
			}
		];
		this.total = 0;
		this.total_net = 0;
		this.total_tax = 0;
	}
}

export class IBooking extends SerializeStoreObject {
	id: string | null;
	state: string;
	no: string;
	truck: string;
	time_start: string;
	time_end: string;
	client: string | null;
	profession: string;
	source: string;
	voucher: string;
	_media: any[];
	ask_source: boolean;
	ask_profession: boolean;
	has_rating: boolean;
	can_upgrade: boolean;
	can_cancel: boolean;
	max_extension: string;
	upsells: IBookingUpsells;
	billing: IBookingBilling;
	step: IBookingSteps;
	_tracking?: boolean;
	_trx_id_booking?: string | null;
	_trx_id_waiver?: string | null;

	constructor(opt?: Partial<IBooking>) {
		super();

		this.id = null;
		this.state = '';
		this.no = '';
		this.truck = '';
		this.time_start = '';
		this.time_end = '';
		this.client = null;
		this.profession = '';
		this.source = '';
		this.voucher = '';
		this._media = [];
		this.ask_source = true;
		this.ask_profession = true;
		this.has_rating = false;
		this.can_upgrade = false;
		this.can_cancel = false;
		this.max_extension = '';
		this.upsells = new IBookingUpsells();
		this.billing = new IBookingBilling();
		this.step = new IBookingSteps();

		Object.assign(this, opt);
	}

	getNextStepRoute(): routesNames {
		if (this.step.next) {
			return checkoutStepRedirector[this.step.next.toLowerCase()];
		}
		return routesNames.home;
	}

	getStepRoute(): routesNames {
		if (this.step.current) {
			return checkoutStepRedirector[this.step.current.toLowerCase()];
		}
		return routesNames.home;
	}
}

export enum ClientInfoType {
	Empty = 'EMPTY',
	Online = 'ONLINE',
	Offline = 'OFFLINE'
}

export class ClientInfo {
	type: ClientInfoType;
	id: string | null;

	constructor(opt?: Partial<ClientInfo>) {
		this.type = ClientInfoType.Empty;
		this.id = null;

		Object.assign(this, opt);
	}
}
