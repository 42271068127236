import Vue from 'vue';

const cookieConsent = Vue.extend({
	data() {
		return {
			googleMapsConsent: false
		};
	},
	mounted() {
		this.updateGoogleMapsConsent();
		if (process.client) {
			window.addEventListener('ccm19CookieAccepted', this.updateGoogleMapsConsent);
			window.addEventListener('ccm19CookieDeclined', this.updateGoogleMapsConsent);
			window.addEventListener('ccm19EmbeddingAccepted', this.updateGoogleMapsConsent);
		}
	},
	beforeDestroy() {
		if (process.client) {
			window.removeEventListener('ccm19CookieAccepted', this.updateGoogleMapsConsent);
			window.removeEventListener('ccm19CookieDeclined', this.updateGoogleMapsConsent);
			window.removeEventListener('ccm19EmbeddingAccepted', this.updateGoogleMapsConsent);
		}
	},
	computed: {
		isGoogleMapsConsentGiven(): boolean {
			return this.googleMapsConsent;
		}
	},
	methods: {
		openCookieConsent() {
			if (process.client) {
				if (typeof CCM !== 'undefined') {
					CCM.openWidget();
				}
			}
		},
		openGoogleMapsConsent() {
			if (process.client) {
				if (typeof CCM !== 'undefined' && CCM.openDetailsWindowAtEmbedding) {
					CCM.openDetailsWindowAtEmbedding('66b1f56216ce50d1700cacf2');
				}
			}
		},
		updateGoogleMapsConsent(): void {
			if (process.client) {
				if (typeof CCM !== 'undefined' && CCM.acceptedEmbeddings) {
					this.googleMapsConsent = CCM.acceptedEmbeddings.some((embedding: any) => embedding.name === 'Google Maps');
				} else {
					this.googleMapsConsent = false;
				}
			}
		}
	}
});

export default cookieConsent;
