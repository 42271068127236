import { Middleware } from '@nuxt/types';

const abTest: Middleware = ({ app, req, res }) => {
	let group: string | null = null;

	if (process.server) {
		const cookies = req.headers.cookie ? req.headers.cookie.split('; ') : [];
		const abTestCookie = cookies.find((c) => c.startsWith('ab-test-group='));

		if (abTestCookie) {
			group = abTestCookie.split('=')[1];
		} else {
			group = Math.random() < 0.5 ? 'A' : 'B';
			if (res) {
				res.setHeader('Set-Cookie', `ab-test-group=${group}; Path=/; HttpOnly`);
			}
		}
	} else {
		group = app.$cookies.get('ab-test-group');
	}

	app.abTestGroup = group;
};

export default abTest;
