import { AModel } from '@/types/class_helper';

export class Customer extends AModel {
	id: string;
	gender: string | null;
	firstname: string;
	lastname: string;
	fullname: string;
	date_of_birth: string | null;
	company: string;
	vat_number: string | number | null;
	registration_number: string | null
	address: {
		country: string;
		postcode: string;
		city: string;
		street: string;
		houseno: string;
		addition: string;
	};

	email: string;
	phone: string;
	state: string;
	newsletter: boolean;
	overdue_invoices: boolean;
	custno: {
		at: string;
		de: string;
	};

	abo: {
		active: boolean;
		date_start: string | null;
		date_end: string | null;
	}

	flags: any[];
	is_complete: boolean;
	has_password: boolean;
	booking_count: number;
	accept_surveillance: boolean;
	identity_document: string;
	drivers_license: string;

	constructor(opt?: Partial<Customer>) {
		super();

		// Default values (all props)
		this.id = '';
		this.gender = null;
		this.firstname = '';
		this.lastname = '';
		this.fullname = '';
		this.date_of_birth = null;
		this.company = '';
		this.vat_number = '';
		this.registration_number = '';
		this.address = {
			country: '',
			postcode: '',
			city: '',
			street: '',
			houseno: '',
			addition: ''
		};
		this.email = '';
		this.phone = '';
		this.state = '';
		this.newsletter = false;
		this.overdue_invoices = false;
		this.custno = {
			at: '',
			de: ''
		};
		this.abo = {
			active: false,
			date_start: null,
			date_end: null
		};
		this.flags = [];
		this.is_complete = false;
		this.has_password = false;
		this.booking_count = 0;
		this.accept_surveillance = false;
		this.identity_document = '';
		this.drivers_license = '';

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			// Assign values passed to the constructor
			Object.assign<Customer, Partial<Customer>>(this, sanitized);
		}
	}

	copy(): Customer {
		const copy = new Customer(this.toObject());
		return copy;
	}

	toPayload(): Record<string, unknown> {
		// Format phone
		const phone = this.phone ? this.phone.replace(/ /g, '').replace('+', '00') : '';

		return {
			gender: this.gender,
			firstname: this.firstname,
			lastname: this.lastname,
			date_of_birth: this.date_of_birth,
			company: this.company,
			address: this.address,
			phone: phone,
			vat_number: (typeof this.vat_number === 'string' && this.vat_number.length > 0) ? this.vat_number : null,
			registration_number: (typeof this.registration_number === 'string' && this.registration_number.length > 0) ? this.registration_number : null
		}
	}
}
