export default {
	basic: {
		id: 'BASIC',
		name: 'upsells.packages.features.basic.name',
		btn_name: 'Basic',
		color: 'cyan123',
		features: ['upsells.packages.features.basic.bp0', 'upsells.packages.features.basic.bp1']
	},
	standard: {
		id: 'STANDARD',
		name: 'upsells.packages.features.standard.name',
		btn_name: 'Standard',
		color: 'orange123',
		features: ['upsells.packages.features.standard.bp0', 'upsells.packages.features.standard.bp1', 'upsells.packages.features.standard.bp2']
	},
	zero: {
		id: 'ZERO',
		name: 'upsells.packages.features.zero.name',
		btn_name: 'Null Risiko',
		color: 'pink123',
		features: [
			'upsells.packages.features.zero.bp0',
			'upsells.packages.features.zero.bp1',
			'upsells.packages.features.zero.bp2',
			'upsells.packages.features.zero.bp3',
			'upsells.packages.features.zero.bp4',
			'upsells.packages.features.zero.bp5'
		]
	}
};
