
import Vue from 'vue';

import UTILS from '@/store/modules/UtilityModule';

import ContactForm from '@/components/general/utils/ContactForm.vue';

export default Vue.extend({
	name: 'FeedbackButton',

	components: { ContactForm },

	props: {
		show: Boolean
	},

	computed: {
		UTILS: () => UTILS,

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		setHigherPosition(): boolean {
			return this.$route.path.includes('profil');
		},

		currentClass(): string {
			if (this.isMobile && this.setHigherPosition) return 'sticky-feedback-btn-fab-high';
			if (this.isMobile) return 'sticky-feedback-btn-fab';

			return 'sticky-feedback-btn';
		},

		dialogFeedback(): boolean {
			return this.$route.query.dialog == 'dialog-feedback-contact';
		},

		hideButton(): boolean {
			return this.$route.path.includes('buchen');
		}
	}
});
